import { required, requiredIf } from 'vuelidate/lib/validators'

// const validAmount = function (value, parentVm) {
//   console.log('valid ' + parentVm)
//   const isChecked = parentVm.invoiceItems.$each.isChecked
//   return isChecked ? value !== 0 : true
// }

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },

  validations: {

    receiptEntryGroup: ['entity.invoiceItems'],
    entity: {
      invoiceItems: {
        $each: {
          receivedAmount: {
            required: requiredIf(function (model) { //requiredIf (first parameter (model) is the model/object)
              return model.isChecked
            }),
            validAmount: function (value, model) { // first parameter (value) is the value of field, second parameter (model) is current model/object
              const isChecked = model.isChecked
              return isChecked ? value !== 0 : true
            }
          },
          receivedDate: {
            required
          }
        }
      }
    }
  }
}
