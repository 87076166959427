
<template>
  <app-side-menu
    :min-width="18"
    :fixed-menu="false">
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Receipt Payment
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link
            :to="{ name: routeTypes.ReceiptPaymentDetail.name, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              Invoices
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import ReceiptPaymentRoutes from './route-types'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'ReceiptPaymentSideMenu',
  components: {
    AppSideMenu
  },
  mixins: [NumberFiltersMixin],
  props: {
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    routeTypes() {
      return ReceiptPaymentRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>