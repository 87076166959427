import { TableColumn } from '@/classes'

const ReceiptPaymentColumns = [
  // new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Invoice No', 'invoiceRef', false, '', 'left'),
  new TableColumn('Invoice Date', 'invoiceDate', false, '', 'left'),
  new TableColumn('Invoice Amount', 'invoiceIncGst', false, '', 'left'),
  new TableColumn('Balance', 'balance', false, '', 'left'),
  new TableColumn('Received Amount', 'recordAmount', false, '', 'left'),
  new TableColumn('Received Date', 'receivedDate', false, '', 'left'),
  new TableColumn('Reference', 'chequeNo', false, '', 'left')
  // new TableColumn('Bank Account', 'bankAccount', false, '', 'left')
]
export { ReceiptPaymentColumns }